/* form */

.form-item-text {
  margin-bottom: 0;
  @media screen and (max-width: @screen-xs-max) {
    margin-bottom: @margin-sm;
    .ant-form-item-label {
      // background: #efefef;
      padding: 0 @padding-sm 0;
    }
  }
}

.ant-form-item-label .multi-line-label {
  white-space: normal;
  padding-bottom: 6px;
}

.form-labels-bold .ant-form-item-label label {
  font-weight: 500;
  font-size: 15px;
}
