.page-container-login {
    margin-top: @margin-lg;
    .card-login-logo {
        justify-content: center;
        margin-bottom: @margin-lg;
    }

    .login-form-button {
        width: 100%;
    }
    .login-bottomLink {
      text-align: center;
      margin-top: @margin-md;
    }
}

.page-container-card {
    margin-top: @margin-lg;
    .card-form-button {
        // background-color: #1890ff;
        width: 100%;
    }
    .card-bottomLink {
      text-align: center;
      margin-top: @margin-md;
    }
}
