.meetings-list .form-type-list-item .ant-list{
  margin-top: 15px;
}

.meetings-list .disabled {
  cursor:not-allowed !important;
  margin-top: 2px;
  margin-bottom: 2px;
  // * {
  //   opacity: 0.85;
  // }
}
