.app-layout {

  .ant-layout-header {
    height: auto;
    padding: 0;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    margin-bottom: 2px;
    // background-color: @component-background;

    .header-logo {
      padding-left: @padding-md;
      img {
        max-height: 70px;
      }
      @media screen and (max-width: @screen-lg-min - 1) {
        display: none;
      }
    }

    .appMenu {
      @media screen and (max-width: @screen-lg-min - 1) {
        display: none;
      }
    }
  }

  .header-col {
    display: flex;
    justify-content: flex-end;
    padding-right: @padding-lg;

    // a {
    //   & span {
    //     color: @black;
    //     &:hover {
    //       color: @primary-color;
    //     }
    //   }
    // }
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 @padding-lg;
    cursor: pointer;
    @media screen and (min-width: @screen-lg-min) {
      display: none;
    }
  }

  // .trigger:hover {
  //   // color: #1890ff;
  // }

}

.appMenu-logo {
  margin: 0 @margin-md 0 @margin-md;
  text-align: center;
  img {
    width: 75%;
    max-width: 150px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.appMenu-logo2 {
  margin: 0 @margin-md 0 @margin-md;
  text-align: center;
  // background-color: rgb(0, 142, 150);
  img {
    width: 100%;
    max-width: 150px;
    height: auto;
  }
}

.appMenu-name {
  text-align: center;
  padding: @padding-md @padding-md 0 @padding-md;
}

.ant-drawer .appMenu, .ant-layout-sider .appMenu {
  margin-top: @margin-md
}


// page styles
.page-header {
  margin-bottom: @margin-lg;
}

.page-container {
  margin: 0 @margin-lg @margin-md @margin-lg;
}
// side menu report dropdown title
// .ant-menu-title-content{
//   color:red
// }

// .ant-layout-sider{
//   background-color:	red !important;
// }

// .ant-menu-item{
//   color: white !important

// }
// .ant-menu-title-content{
//   color: white !important

// }

.page-padding-topBottom {
  padding-top: @padding-lg;
  padding-bottom: @padding-lg;
}
.page-padding-leftRight {
  padding-left: @padding-lg;
  padding-right: @padding-lg;
}

// layout misc
.center {
  text-align: center;
}

// super admin sideMenu and button
.adminSideMenu .ant-layout-sider-children {
  height: 100%;
  min-height: 100%;
	display: flex;
	flex-direction: column;

  > .mainSideMenu, > .superAdminSideMenu{
    flex: 1;
  }
}
.dropDownSuperAdmin {
  .ant-btn:first-child {
    width: 100%;
  }
}

// choose layout
.chooseLayout-card {
    margin-top: @margin-lg;
    .form-button {
        width: 100%;
    }
}

.sideMenu-name {
  text-align: center;
  padding: @padding-md @padding-md 0 @padding-md;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
