/* WYSIWYG Editor  */
.text-editor .ql-editor {
  min-height: 180px;
}

.text-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: rgb(245, 245, 245) ;
  cursor: not-allowed;
  opacity: 1;
}